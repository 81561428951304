/*
 * Финансируем любые сегменты
 *
 */

import PropTypes from "prop-types";
import React from "react";

import { Box, Typography } from "@material-ui/core";
import { SectionContainer } from "@product-site-frontend/shared";
import parse from "html-react-parser";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import CardBusinessSegment from "./CardBusinessSegment";

SwiperCore.use([Navigation, Pagination]);

SectionBusinessSegments.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    cards: PropTypes.array,
  }),
};

export default function SectionBusinessSegments({ content }) {
  const { cards = [], description = '', title } = content;

  function renderCardsRow(cards, index) {
    return (
      <Box key={`line-${index}`} sx={{ display: "flex", justifyContent: "center", mx: -1 }}>
        {cards.map((card, i) => (
          <Box key={i} sx={{ p: 1, width: 1 / 3 }}>
            <CardBusinessSegment {...card} />
          </Box>
        ))}
      </Box>
    );
  }

  function renderCardsRows(cards) {
    const renderLinesArray = [];

    for (let index = 0; index < cards.length; index += 3) {
      if (index < cards.length + 3) {
        renderLinesArray.push([index, index + 3]);
      } else {
        renderLinesArray.push([index]);
      }
    }

    return (
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        {(renderLinesArray.map((line, index) => {
          if (line.length === 2) {
            return renderCardsRow(cards.slice(line[0], line[1]), index)
          } else {
            return renderCardsRow(cards.slice(line[0]), index)
          }
        }))}
      </Box>
    );
  }

  return (
    <SectionContainer id={"sectionBusinessSegments"} title={parse(title)}>
      <Typography component="div" sx={{ mb: 6 }} variant="subtitle1" >
        {parse(description)}
      </Typography>
      <Box sx={{ display: { lg: "none" } }}>
        <Swiper slidesPerView={1.3} spaceBetween={24}>
          {cards.map(card => (
            // eslint-disable-next-line react/prop-types
            <SwiperSlide key={card.text}>
              <CardBusinessSegment {...card} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      {renderCardsRows(cards)}
    </SectionContainer>
  );
}
