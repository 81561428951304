import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box, Button, Container, Typography } from '@material-ui/core';
import { HelpVideo, ApplicationContext } from '@product-site-frontend/shared';
import { useLocation } from '@reach/router';
import { GatsbyImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';

function Subscript(props) {
  return (
    <Typography
      component="span"
      sx={{ fontSize: "0.75em" }}
      variant="inherit"
      {...props}
    />
  );
}

function Caption(props) {
  return (
    <Typography
      component="div"
      sx={{ color: "text.secondary", lineHeight: 1.4 }}
      variant="caption"
      {...props}
    />
  );
}

SectionMain.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    bgDesktop: PropTypes.array,
    bgMobile: PropTypes.object,
    term: PropTypes.number,
    dealPercent: PropTypes.number,
    advanceRate: PropTypes.number,
  }),
};

export default function SectionMain({ content }) {
  const { search } = useLocation();

  const { setAmount, setApplicationOpen } = useContext(ApplicationContext);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [fadeOutSlide, setFadeOutSlide] = useState(null);

  const handleApplicationClick = useCallback(() => {
    setAmount('');
    setApplicationOpen(true);
  }, [setApplicationOpen, setAmount]);

  const handleChangeSlide = () => {
    if (content?.bgDesktop?.length > 0 && currentSlide + 1 < content?.bgDesktop.length) {
      setFadeOutSlide(currentSlide);
      setCurrentSlide(currentSlide + 1);
    } else {
      setFadeOutSlide(currentSlide);
      setCurrentSlide(0);
    }
  };

  useEffect(() => {
    let query = new URLSearchParams(search);

    const showModalLeasing = query.get("showModalLeasing");
    if (showModalLeasing) {
      handleApplicationClick();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('short_app')) {
      setApplicationOpen(true);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setApplicationOpen]);

  useEffect(() => {
    const sliderInterval = setInterval(handleChangeSlide, 3000);

    return () => {
      clearInterval(sliderInterval);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide]);

  return (
    <Box
      component="section"
      mb={{ xs: 3, lg: 6 }}
      overflow="hidden"
      position="relative"
      sx={{
        background: 'linear-gradient(113.6deg, rgba(255, 120, 15, 0.08) -9.09%, rgba(0, 178, 255, 0.08) 69.6%)',
        padding: {
          xs: '260px 0 40px',
          md: '276px 0 40px',
          lg: '70px 0'
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          overflow: "hidden",
          width: {
            xs: '100%',
            lg: 0.5
          },
          minHeight: { xs: 200, md: 256 },
          mx: { xs: -3, md: -4, lg: 0 },
          bottom: {
            lg: 0
          },
          right: 0,
          top: {
            xs: 20,
            lg: "-20px"
          }
        }}
      >
        {content?.bgDesktop?.length > 0 && content.bgDesktop.map((backElement, idx) => {
          return (
            <Box
              className={idx === currentSlide ? 'active' : idx === fadeOutSlide ? 'fadeOut' : undefined}
              key={idx}
              sx={{
                width: "90%",
                ml: { xs: 0, lg: "10%" },
                position: "absolute",
                bottom: 0,
                right: 0,
                left: 0,
                maxHeight: {
                  xs: 200,
                  md: 256,
                  lg: 620
                },
                transition: 'all .3s cubic-bezier(.4,0,.2,1)',
                transform: 'translate(25%, -33%)',
                opacity: 0,

                '&.active': {
                  opacity: '1',
                  transform: {
                    xs: 'translate(0, 0)',
                    lg: 'translate(0, -10%)'
                  },
                },

                '&.fadeOut': {
                  opacity: '0',
                  transform: 'translate(-50%, 66%)'
                }
              }}
            >
              <Box
                alt={backElement.alternativeText || ""}
                component={GatsbyImage}
                image={backElement.localFile.childImageSharp.gatsbyImageData}
                loading="lazy"
                objectFit="contain"
                sx={{
                  maxHeight: {
                    xs: 200,
                    md: 256,
                    lg: 500
                  },
                  display: 'block',
                  marginRight: {
                    xs: 0,
                    md: 0,
                  },
                  "& img": {
                    objectFit: "contain !important"
                  },
                }}
              />
            </Box>
          );
        })}
      </Box>

      <Container maxWidth="lg" sx={{ zIndex: 2 }}>
        <Box
          display="flex"
          flexDirection={{ xs: "column-reverse", lg: "row" }}
        >
          <Box
            sx={{
              maxWidth: "640px",
              mt: { xs: 4, lg: 0 },
              mb: { xs: 2, lg: 0 },
              width: { xs: 1, lg: 0.6 },
            }}
          >
            <Typography component="div" sx={{ mb: { xs: 4, lg: 6 } }} variant="h1">
              {parse(content.title)}
            </Typography>
            <Typography component="div" sx={{ mb: { xs: 4, lg: 6 } }} variant="subtitle1">
              {parse(content.description)}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              maxWidth={600}
              mb={{ xs: 4, lg: 7 }}
              overflow="auto"
              pb={2}
            >
              <Box>
                <Typography component="div" variant="h2">
                  <Subscript>до</Subscript>
                  &nbsp;{content.dealPercent}%
                </Typography>
                <Caption>заявок одобряем ежедневно</Caption>
              </Box>
              <Box ml={5}>
                <Typography component="div" variant="h2">
                  <Subscript>от</Subscript>
                  &nbsp;{content.advanceRate}
                  <Subscript>%</Subscript>
                </Typography>
                <Caption>авансовый платеж</Caption>
              </Box>
              <Box sx={{ ml: 5 }}>
                <Typography component="div" variant="h2">
                  <Subscript>до</Subscript>
                  &nbsp;
                  {content.term}
                  <Subscript>мес</Subscript>
                </Typography>
                <Caption>срок финансирования</Caption>
              </Box>
            </Box>
            <Box
              display="flex"
              flexWrap={{ xs: "wrap", md: "nowrap" }}
              justifyContent={{ xs: "center", lg: "normal" }}
            >
              <Box
                sx={{
                  maxWidth: 375,
                  mb: { xs: 3, lg: 0 },
                  width: { xs: 1, lg: "auto" },
                  py: 2,
                }}
              >
                <Button
                  color="primary"
                  fullWidth
                  onClick={handleApplicationClick}
                  size="large"
                  sx={{ height: '56px', borderRadius: '28px' }}
                  variant="contained"
                >
                  Оформить лизинг
                </Button>
              </Box>
              <Box
                maxWidth={375}
                ml={{ lg: 3 }}
                sx={{
                  padding: '8px 0',
                  flexGrow: '1',

                  '& button': {
                    padding: '8px 22px',
                    height: '56px',
                    background: '#fff',
                    borderRadius: '28px',
                    boxShadow: '0px 10px 32px 0px #0D1D3214'
                  },
                  '& svg': {
                    height: '26px',
                    width: 'auto',
                    fill: '#ff2b42'
                  }
                }}
                width={{ xs: 1, lg: "auto" }}
              >
                <HelpVideo
                  videoText={
                    <>
                      узнайте все о лизинге за 80 секунд
                    </>
                  }
                  videoUrl="https://www.youtube.com/watch?v=VJ1wK48wOQs"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Container >
    </Box >
  );
}
